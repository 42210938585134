<template>
  <div class="privacy">
    <!-- <Transition name="fade">
      <Loader v-if="!$store.state.authIsReady || $store.state.globalLoader.show" :header="$store.state.globalLoader.head"  :subheader="$store.state.globalLoader.subheader"></Loader>
    </Transition> -->
    <div class="relative">
      <!-- Header -->
      <Disclosure as="nav" class="bg-white" v-slot="{ open }">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between border-b border-gray-200 py-6 md:justify-start md:space-x-10">

              <div class="flex justify-start lg:w-0 lg:flex-1">
                <img class="h-8 w-auto sm:h-10" src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Flogo%2Bnapis.svg?alt=media&token=3ecb12fa-7975-47cd-a69e-79a96f199b10" alt="Complaia complaint system in web" />
              </div>

            <nav class="hidden px-2 space-y-1 items-center justify-end sm:flex gap-2">
              <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[$route.path === item.href ? ' text-gray-900' : 'text-gray-600  hover:text-gray-900 ', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer select-none']">
              <component  :is="item.icon" :class="[$route.path === item.href ? 'text-purple-500 mr-3 flex-shrink-0 h-6 w-6' : 'group-hover:text-purple-300  text-gray-300 mr-3 flex-shrink-0 h-6 w-6 ']" aria-hidden="true" />
                <span :class="[$route.path === item.href ? 'text-purple-500' : 'transition ease-in-out duration-300 group-hover:text-purple-300 text-gray-400']">{{ item.name }} </span>
              </router-link>
            </nav>

              <!-- <div class="hidden items-center justify-end md:flex md:flex-1 lg:w-0"> -->
                <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
                <!-- <router-link to="/"> -->
                <!-- <a class="cursor-pointer ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-purple-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purple-600">Dashboard</a></router-link> -->
              <!-- </div> -->


            <div class="-mr-2 flex items-center sm:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span class="sr-only">Open main menu</span>
                <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                <MenuIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
          </div>
        </div>

        <DisclosurePanel class="sm:hidden">
          <div class="space-y-1 pt-2 pb-3">
            <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
            <DisclosureButton as="a" href="#" class="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700">Dashboard</DisclosureButton>
            <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Team</DisclosureButton>
            <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Projects</DisclosureButton>
            <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Calendar</DisclosureButton>
          </div>
        </DisclosurePanel>
      </Disclosure>

      <!-- Page Header -->
      <div class="relative mx-auto max-w-2xl py-24 px-4 sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8">
        <div class="relative">
          <h1 class="text-3xl font-bold tracking-tight text-purple-500 sm:text-5xl sm:leading-none lg:text-6xl">{{$route.meta.pageTitle}}.</h1>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Notification></Notification>
    </transition>
    <div class="flex flex-col flex-1">
      <main class="flex-1">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <router-view></router-view>
          </div>
        </div>
      </main>
    </div>

    <footer class="bg-gray-50" aria-labelledby="footer-heading">
      <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div class="mt-12 border-t border-gray-200 pt-8 flex justify-center">
          <p class="text-base text-gray-400 xl:text-center">&copy; 2022 Complaia Systems Sp. z o.o.  All rights reserved.</p>
        </div>
      </div>
    </footer>

  </div>
</template>
<script>

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MenuIcon, FingerPrintIcon, ClipboardCopyIcon, ReceiptRefundIcon, BookmarkIcon, DocumentSearchIcon } from '@heroicons/vue/outline';
// import Loader from '../components/Loader.vue';
import Notification from '../components/Notification.vue';

export default {
  components: {
    Disclosure, DisclosureButton, DisclosurePanel, MenuIcon, FingerPrintIcon, ClipboardCopyIcon, ReceiptRefundIcon, BookmarkIcon, DocumentSearchIcon,
    // Loader, 
  // Nav,
  Notification,
  },
  name: "Privacy",
  data() {
    return {
      navigation: [
        { name: 'Privacy Policy', href: '/privacy/privacy-policy', icon: 'FingerPrintIcon' },
        { name: 'Copyright Policy', href: '/privacy/copyright-policy', icon: 'ClipboardCopyIcon' },
        { name: 'Refund Policy', href: '/privacy/refund-policy', icon: 'ReceiptRefundIcon' },
        { name: 'Terms Conditions', href: '/privacy/terms_conditions', icon: 'DocumentSearchIcon'},
        { name: 'Disclaimer', href: '/privacy/disclaimer', icon: 'BookmarkIcon' },
      ],
    }
  },
  created() {
    if(this.$route.path === '/privacy/' || this.$route.path === '/privacy') {
      this.$router.push('/privacy/privacy-policy')
    }
  }
  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
